  <div id="welcome">
      <img id="user-img" class="rounded-circle"
      src="https://avatars.githubusercontent.com/u/949014?s=60&v=4" alt="">
      <div id="user-msg">Welcome Rudy!</div>
  </div>
      <button id="compose-btn" class="btn" >COMPOSE</button>
  <ul>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(1) }" (click)="setInbox(1)">Inbox (1)</a></li>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(2) }" (click)="setInbox(2)">Important</a></li>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(3) }" (click)="setInbox(3)">Tagged</a></li>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(4) }" (click)="setInbox(4)">Sent Mail</a></li>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(5) }" (click)="setInbox(5)">Drafts</a></li>
      <li><a href="#" [ngClass]="{ 'nav-list-highlight' : isActive(6) }" (click)="setInbox(6)">Trash</a></li>
  </ul>
