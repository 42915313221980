import { Component, OnInit } from "@angular/core";


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-reply]',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.css']
})
export class ReplyComponent {

  constructor() { }

}
